import * as React from 'react';
import ErrorSmall from 'wix-ui-icons-common/on-stage/ErrorSmall';
import { ErrorMessageType } from '../schemas/InputElements.schema';
import style from '../style/inlineErrorMessage.scss';

export interface InlineErrorMessageProps {
  errorMessage?: string;
  errorMessageType: ErrorMessageType;
  shouldShowValidityIndication: boolean;
}

export const InlineErrorMessage: React.FC<InlineErrorMessageProps> = ({
  errorMessage,
  errorMessageType,
  shouldShowValidityIndication,
}) => {
  const hasInlineErrorMessage =
    errorMessageType === 'inline' &&
    errorMessage &&
    shouldShowValidityIndication;

  return hasInlineErrorMessage ? (
    <div className={style.inlineErrorIndication}>
      <ErrorSmall className={style.iconErrorMessage} />
      <div className={style.txterrmsg}>{errorMessage}</div>
    </div>
  ) : null;
};
